<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <h1 class="text-light mb-3">Messages</h1>
        <div v-if="!settings.disable_messaging">
            <ul
                v-if="conversations && conversations.length != 0"
                class="list-group shadow-sm bg-white"
            >
                <li class="list-group-item py-3 text-muted">
                    {{ t('pages.messages.explanation') }}
                </li>
                <li
                    class="list-group-item py-3 tap-react"
                    v-for="(conversation, index) in conversations"
                    :key="conversation.person.id"
                    @click="router.push(`/messages/conversation/${index}`)"
                >
                    <div
                        class="d-flex flex-row align-items-center"
                        style="gap: 0.6rem"
                    >
                        <img
                            :src="captainProfilePicture(conversation.person)"
                            class="profile-picture"
                        />
                        <div class="flex-grow-1" style="overflow: hidden">
                            <div
                                class="d-flex flex-row justify-content-between align-items-start"
                            >
                                <div>
                                    <span style="font-weight: 500"
                                        >{{ t('pages.messages.captain') }}
                                    </span>
                                    {{ ' ' + conversation.person.last_name }}
                                </div>
                                <div
                                    class="text-muted timestamp"
                                    v-if="
                                        conversation.messages &&
                                        conversation.messages.length
                                    "
                                >
                                    {{
                                        moment(
                                            conversation.messages[0].created_at
                                        ).format('HH:mm')
                                    }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    conversation.messages &&
                                    conversation.messages.length != 0
                                "
                                class="text-muted text-preview w-100"
                            >
                                <span
                                    v-if="
                                        conversation.messages[0].from_user_id ==
                                        loggedInUser.id
                                    "
                                    >{{ t('pages.messages.you') }}
                                </span>
                                <span v-else>
                                    <span
                                        v-if="conversation.has_unread"
                                        class="badge bg-danger text-white"
                                        >{{ t('pages.messages.new') }}</span
                                    >
                                </span>
                                {{ conversation.messages[0].message }}
                            </div>
                            <div v-else class="text-muted">
                                {{ t('pages.messages.sendNew') }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div
                v-else
                class="card shadow-sm p-3 text-center text-muted d-flex justify-content-center align-items-center"
                style="min-height: 10rem"
            >
                {{ t('pages.messages.empty') }}
            </div>
        </div>
        <div v-else class="card p-3 shadow-sm mb-3">
            <h5>Undergoing maintenance</h5>
            <p>
                We're sorry, but we're currently performing maintenance on the
                messaging system to provide the best experience possible. We're
                sorry for any inconvenience and we will be back as soon as
                possible.
            </p>

            <a href="#" class="btn btn-primary" onclick="location.reload()"
                >Check again</a
            >
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useMessageStore } from '@/stores/messages-store';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { useUserStore } from '@/stores/user-store';
import placeholderProfilePicture from '@/assets/user-placeholder.png';
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';
const { t } = useI18n();
const moment = inject('moment');
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const router = useRouter();

const remoteSettingsStore = useRemoteSettingsStore();
const { settings } = storeToRefs(remoteSettingsStore);

const userStore = useUserStore();
const loggedInUser = storeToRefs(userStore).user;

const messageStore = useMessageStore();
const { conversations } = storeToRefs(messageStore);
const { unreads } = storeToRefs(messageStore);

async function indexMessages() {
    // This function will fetch all the people, and get the latest messages of each people
    const res = await axios.get('messages/people');
    unreads.value = 0;
    if (res.data && res.data.length) {
        res.data.forEach((conversation) => {
            if (conversation.has_unread) {
                unreads.value++;
            }
        });
    }
    conversations.value = res.data;
}

function captainProfilePicture(user) {
    if (user.passport_photo == null || user.passport_photo == '') {
        return placeholderProfilePicture;
    }
    return GLOBAL_VAR_BACKEND_STORAGE_URL + user.passport_photo;
}

onMounted(() => {
    remoteSettingsStore.fetchSettings();
    indexMessages();
});
</script>

<style scoped>
.profile-picture {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    margin: 0;
    border-radius: 100%;
}

.timestamp {
    margin: 0;
    font-size: 0.9rem;
}

.text-preview {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}
</style>
